export const navDelay = 500;

export const homeDelay = 1000;

export const welcomeDelay = 3000;

export const aboutDelay = 1500;

export const projectsDelay = 1600;

export const contactDelay = 1700;

export const footerDelay = 1800;

export const transition = 100;
