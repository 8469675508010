const compliments = [
  "If they based a movie on you, it would win an oscar because you're that incredible.",
  "I pretty much only feel comfortable when I'm around you. You're like the sweatpants of my life.",
  "You know that guy in scary movies, who is like, 'Hey, let's split up.' That friend is dumb. You are not that friend.",
  "If you were a fruit, you'd be a fine-apple.",
  "Your extreme mental and physical appeal makes me want to live long and prosper with you.",
  "I know it's cheesy, but I think you're grate.",
  "Talking to you is the best part of my day... aside from when I'm eating and sleeping.",
  "I don't know if sarcasm is a skill, but you've certainly mastered it.",
  "You're like the one sock that dissapears. I don't know what I did to lose you but I want you back.",
  "You know what's awesome? Chocolate cake... oh, and your face.",
  "You're not lazy. All the people around you are way too active.",
  "Your smile is proof that the best things in life are free.",
];

export default compliments;
